"use server";

import { currentUserId } from "./server";
import * as articleRepo from "~/repos/article";
import { ArticleUpdate, NewArticle } from "~/types";

export const fetch = async (id: string) => {
  return await articleRepo.find(id);
};

export const create = async (val: Omit<NewArticle, "authorId">) => {
  const authorId = await currentUserId();
  if (!authorId) throw "user not authed";

  await articleRepo.create({ ...val, authorId });
};

export const update = async (
  id: string,
  { title, abstract, content, publishedAt }: ArticleUpdate
) => {
  const authorId = await currentUserId();
  if (!authorId) throw "user not authed";

  await articleRepo.update(
    id,
    {
      title,
      abstract,
      content,
      publishedAt,
    },
    authorId
  );
};

export const destroy = async (id: string) => {
  const authorId = await currentUserId();
  if (!authorId) throw "user not authed";
  await articleRepo.destroy(id, authorId);
};
